import React, { useState } from "react";

// creating functional component ans getting props from app.js and destucturing them
export default function StepThree({ nextStep, handleFormData, prevStep, values }) {
  //creating error state for validation
  const [error, setError] = useState(false);

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();

    const validate = (values) => {
        let errors = {};
        let errorMsg = 'This field is required!'

        if(values.customerDetails.trim() === '') {
            errors.customerDetails = errorMsg;
        }
        if(values.reasonsCustomerVisit.trim() === '') {
            errors.reasonsCustomerVisit = errorMsg;
        } 
    
        return errors;
    };

    if(Object.keys(validate(values)).length === 0) {     
        nextStep();
        // console.log(values)
    } else {
        setError(validate(values));
    }
};

  return (
    <div>
      <form onSubmit={submitFormData}>

        <h2 className='mb-5 text-center text-capitalize'>Who is this for</h2>

        <p className="col-12 col-md-12">
          <label htmlFor="customerDetails" className='form-label'>Tell us about your ideal customer. Who are they? How old are they? What gender are they? Where do they hang out online? What are their interests? If you're unsure that isn't a problem, we'll work on this together.</label>
          <textarea 
            className={`form-control ${error.customerDetails && 'is-invalid'}`}
            name="customerDetails"
            id="customerDetails"
            defaultValue={values.customerDetails}
            rows="3"
            onChange={handleFormData("customerDetails")}
          />
          {error.customerDetails && (
            <span className='invalid-feedback'>
                {error.customerDetails}
            </span>
          )}
        </p>

        <p className="col-12 col-md-12">
          <label htmlFor="reasonsCustomerVisit" className='form-label'>What are the top 5 reasons your ideal customer will visit your website?</label>
          <textarea 
            className={`form-control ${error.reasonsCustomerVisit && 'is-invalid'}`}
            name="reasonsCustomerVisit"
            id="reasonsCustomerVisit"
            defaultValue={values.reasonsCustomerVisit}
            rows="3"
            onChange={handleFormData("reasonsCustomerVisit")}
          />
          {error.reasonsCustomerVisit && (
            <span className='invalid-feedback'>
                {error.reasonsCustomerVisit}
            </span>
          )}
        </p>

        <div className='col-12 text-center'>
          <button type="submit" onClick={prevStep} className="btn btn-orange default-padding me-3 shadow text-capitalize" style={{ width: '150px' }}> Previous </button>
          <button type="submit" className="btn btn-primary default-padding shadow text-capitalize" style={{ width: '150px' }}> Continue </button>
        </div>

      </form>
    </div>
  );
};
