import React from 'react'

export default function GetStartedBanner() {
    return (
        <div className='w-100 bg-primary'>
            <div className='container'>
                <div className='row align-items-center row-padding' style={{ height: '400px' }}>
                    <div className='col-md-8 text-white'>
                    <h1 className='display-4'>Get Started</h1>
                    <p className='fs-4'>Take the leap and see what GoogolWeb can do for you and your business.</p>
                    </div>
                </div>
            </div> 
        </div>
    )
}
