import React, { useState } from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Layout from "../components/Layout";
import { SEO } from "../components/seo";
import GetStartedBanner from "../components/GetStartedBanner";
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import Fifth from "./Fifth";

export default function GetStarted() {

    //state for steps
    const [step, setstep] = useState(1);

    //state for form data
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        businessName: "",
        businessLocation: "",
        businessServices: "",
        decisionMakers: "",
        projectTimeframe: "",
        projectContent: "",
        projectBudget: "",
        addOtherServices: "",
        servicesToAdd: "",
        eCommerceToCreate: "",
        // hasSocialMeadia: "",
        whereSocialMedia: "",
        addSocialMedia: "",
        goalNumber1: "",
        goalNumber2: "",
        goalNumber3: "",
        goalNumber4: "",
        goalNumber5: "",
        customerDetails: "",
        reasonsCustomerVisit: "",
        designConcept1: "",
        designConcept2: "",
        designConcept3: "",
        designConcept4: "",
        whatIsSuccess1: "",
        whatIsSuccess2: ""
    })


    // function for going to next step by increasing step state by 1
    const nextStep = () => {
        setstep(step + 1);
    };

    // function for going to previous step by decreasing step state by 1
    const prevStep = () => {
        setstep(step - 1);
    };

    // handling form input data by taking onchange value and updating our previous form data state
    const handleInputData = input => e => {
        // input value from the form
        const {value } = e.target;

        //updating for data state taking previous state and then adding new value to create new object
        setFormData(prevState => ({
            ...prevState,
            [input]: value
        }));
    }

    switch (step) {
        case 1:
            return (
                <GoogleReCaptchaProvider
                    reCaptchaKey="6Lfsi7khAAAAAF2vaMtTTYYm5EWoVkQZwSlDwl57">
                    <Layout>
                        <section className='abstract text-dark' style={{ marginTop: '74px' }}>
                            <GetStartedBanner></GetStartedBanner>
                            <div className='container'>
                                <div className='row row-padding' style={{padding: '100px 0'}}>
                                    <div className='col-12 col-md-8 mx-auto'>
                                        <First nextStep={nextStep} handleFormData={handleInputData} values={formData} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Layout>
                </GoogleReCaptchaProvider>
            );
        case 2:
            return (
                <GoogleReCaptchaProvider
                    reCaptchaKey="6Lfsi7khAAAAAF2vaMtTTYYm5EWoVkQZwSlDwl57">
                    <Layout>
                        <section className='abstract text-dark' style={{ marginTop: '74px' }}>
                            <GetStartedBanner></GetStartedBanner>
                            <div className='container'>
                                <div className='row row-padding' style={{padding: '100px 0'}}>
                                    <div className='col-12 col-md-8 mx-auto'>
                                        <Second nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Layout>
                </GoogleReCaptchaProvider>
            );
        case 3:
            return (
                <GoogleReCaptchaProvider
                    reCaptchaKey="6Lfsi7khAAAAAF2vaMtTTYYm5EWoVkQZwSlDwl57">
                    <Layout>
                        <section className='abstract text-dark' style={{ marginTop: '74px' }}>
                            <GetStartedBanner></GetStartedBanner>
                            <div className='container'>
                                <div className='row row-padding' style={{padding: '100px 0'}}>
                                    <div className='col-12 col-md-8 mx-auto'>
                                        <Third nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData}  />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Layout>
                </GoogleReCaptchaProvider>
            );
        case 4:
            return (
                <GoogleReCaptchaProvider
                    reCaptchaKey="6Lfsi7khAAAAAF2vaMtTTYYm5EWoVkQZwSlDwl57">
                    <Layout>
                        <section className='abstract text-dark' style={{ marginTop: '74px' }}>
                            <GetStartedBanner></GetStartedBanner>
                            <div className='container'>
                                <div className='row row-padding' style={{padding: '100px 0'}}>
                                    <div className='col-12 col-md-8 mx-auto'>
                                        <Fourth nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData}  />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Layout>
                </GoogleReCaptchaProvider>
            );
        case 5:
            return (
                <GoogleReCaptchaProvider
                    reCaptchaKey="6Lfsi7khAAAAAF2vaMtTTYYm5EWoVkQZwSlDwl57">
                    <Layout>
                        <section className='abstract text-dark' style={{ marginTop: '74px' }}>
                            <GetStartedBanner></GetStartedBanner>
                            <div className='container'>
                                <div className='row row-padding' style={{padding: '100px 0'}}>
                                    <div className='col-12 col-md-8 mx-auto'>
                                        <Fifth prevStep={prevStep} handleFormData={handleInputData} values={formData}  />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Layout>
                </GoogleReCaptchaProvider>
            );
        // default case to show nothing
        default:
            return (
                <GoogleReCaptchaProvider
                    reCaptchaKey="6Lfsi7khAAAAAF2vaMtTTYYm5EWoVkQZwSlDwl57">
                    <Layout>
                        <section className='abstract text-dark' style={{ marginTop: '74px' }}>
                            <GetStartedBanner></GetStartedBanner>
                            <div className='container'>
                                <div className='row row-padding' style={{padding: '100px 0'}}>
                                    <div className='col-12 col-md-8 mx-auto'>
                                        <First nextStep={nextStep} handleFormData={handleInputData} values={formData} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Layout>
                </GoogleReCaptchaProvider>
            );
    }

}

export const Head = () => (
    <SEO title="Get Started" />
)